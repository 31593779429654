<template>
    <q-footer elevated>
        <div class="fixed-bottom footer shadow-9 q-pa-sm"
             align="right"
             style="background-color: #f0f0f0; color: #000;"
        >
            (C) AbyBroker S.r.l. - Tutti i diritti riservati
        </div>
    </q-footer>
</template>

<script>

export default {
    name: "BoxInformativo_Footer",
    data() {
        return {
        }
    },
    components: {
    },
    computed: {
    }
}
</script>

<style>
    .footer {
        background-color: #f0f0f0;
        border: 1px SOLID #808080;
    }
</style>
